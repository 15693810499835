import type { RefObject } from 'react';
import React, { useRef } from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/components/Link';
import {
  formatClassNames,
  isEmptyObject,
} from '@wix/editor-elements-common-utils';
import type { IMenuItemProps } from '../../../Menu.types';
import { createSDKAction } from './utils';
import classes from './style/MenuItem.scss';
import { testIds } from '../../constants';
import { isCurrentItem } from '../../../../../common/menu/getCurrentMenuItem';
import { semanticClassNames as menuSemanticClassNames } from '../../../Menu.semanticClassNames';
import shmSemanticClassNames from '../../../../StylableHorizontalMenu/StylableHorizontalMenu.semanticClassNames';
import DropdownIcon from '../../assets/dropdownIcon.svg';

const itemLabelClassNames = classNames(
  classes.label,
  formatClassNames(shmSemanticClassNames.menuItemLabel),
  formatClassNames(menuSemanticClassNames.itemLabel),
);

export const MenuItemLabel: React.FC<
  IMenuItemProps & {
    hasDropdownMenu: boolean;
    chevronButtonRef: any;
    setIsHovered: (cb: (prevIsHovered: boolean) => boolean) => void;
    isHovered: boolean;
  }
> = props => {
  const {
    item,
    currentItem,
    hasDropdownMenu,
    onItemClick,
    onItemDblClick,
    onItemMouseIn,
    onItemMouseOut,
    previewState,
    translations,
    chevronButtonRef,
    setIsHovered,
    isHovered,
  } = props;

  const isCurrentPage = isCurrentItem(item, currentItem);
  const hasSubItems = !!item.items?.length;
  const { label, link } = item;
  const hasLink = !isEmptyObject(link);
  const linkRef: RefObject<HTMLAnchorElement> = useRef<HTMLAnchorElement>(null);

  const labelContainerClassNames = classNames(
    classes.labelContainer,
    isCurrentPage ? classes.selected : '',
    formatClassNames(shmSemanticClassNames.menuItemWrapper),
    formatClassNames(menuSemanticClassNames.item),
  );

  const handleItemClick = (event: React.MouseEvent<Element>) => {
    if (!hasLink && hasDropdownMenu) {
      setIsHovered(currentHovered => !currentHovered);
    }
    createSDKAction(item, isCurrentPage, onItemClick)?.(event);
  };

  const handleDropdownIconClick = (
    event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovered(currentHovered => !currentHovered);
  };

  const handleIconKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleDropdownIconClick(event);
    }
  };

  return (
    <Link
      ref={linkRef}
      {...link}
      activateByKey="Enter"
      onClick={handleItemClick}
      onMouseEnter={createSDKAction(item, isCurrentPage, onItemMouseIn)}
      onMouseLeave={createSDKAction(item, isCurrentPage, onItemMouseOut)}
      onDoubleClick={createSDKAction(item, isCurrentPage, onItemDblClick)}
      {...(isCurrentPage && {
        ariaCurrent: 'page',
      })}
      className={labelContainerClassNames}
      dataPreview={previewState}
      dataTestId={testIds.itemLabel}
    >
      <div className={itemLabelClassNames}>{label}</div>

      {hasDropdownMenu && (
        <button
          aria-label={translations.dropdownButtonAriaLabel}
          ref={chevronButtonRef}
          className={classNames(
            classes.dropdownToggleButton,
            !hasSubItems && classes.noDropdownItems,
          )}
          onClick={handleDropdownIconClick}
          aria-expanded={isHovered}
          aria-haspopup={true}
          onKeyDown={handleIconKeyDown}
        >
          <DropdownIcon
            className={classNames(
              isHovered && classes.expandedDropdownIcon,
              formatClassNames(menuSemanticClassNames.itemIcon),
            )}
          />
        </button>
      )}
    </Link>
  );
};
