export const semanticClassNames = {
  root: 'dropdown-menu',
  dropdownItem: 'dropdown-menu__item',
  dropdownItemLabel: 'dropdown-menu__item-label',
  dropdownSubItem: 'dropdown-menu__subitem',
  dropdownSubItemLabel: 'dropdown-menu__subitem-label',
} as const;

// TODO: Change it once we finish the migration from SHM to the New menu
export default {};
